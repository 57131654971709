import {useState} from "react";
import * as S from "./RightsRequestChildren/ThirdPartyApproval-styles";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {
  selectRightsRequest,
  updateRightsRequest,
} from "../../../redux/rightsRequestSlice";

export default function AddAComment() {
  const [showComment, setShowComment] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const rightsRequest = useAppSelector(selectRightsRequest);

  const handleComment = (e: any) => {
    console.log(e.target.value);
    if (e.target.value === "" || !rightsRequest) return;
    const comment = e.target.value;
    dispatch(updateRightsRequest({...rightsRequest, comment}));
  };

  if (!rightsRequest) return null;

  console.log(rightsRequest);

  return (
    <div className="d-flex mt-3 justify-content-md-center ">
      <div className="d-flex flex-column align-items-start col-md-6">
        <S.Link className="mb-1" onClick={() => setShowComment(!showComment)}>
          {rightsRequest.approvalPage.addACommentText
            ? rightsRequest.approvalPage.addACommentText
            : "Add a comment"}
        </S.Link>
        {showComment && (
          <div className="d-flex flex-column w-100">
            <textarea
              placeholder="Add a comment"
              className="p-1"
              onChange={(e) => handleComment(e)}
            ></textarea>
          </div>
        )}
      </div>
    </div>
  );
}
