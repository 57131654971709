import {httpsCallable} from "firebase/functions";
import {functions} from "../firebase";
import {RightsRequest, RightsRequestStatus} from "../types/RightsRequest";
import axios from "axios";

export const fetchRightsRequest = async (
  rightRequestShortId: string
): Promise<RightsRequest | undefined> => {
  try {
    const {data} = await axios.get(
      process.env.REACT_APP_FIREBASE_API_URL + "/rights/" + rightRequestShortId
    );
    return data.rightsRequest as RightsRequest;
  } catch (err) {
    console.error(err);
    return undefined;
  }
};

export const fetchRightsRequestStatus = async (
  rightRequestId: string
): Promise<RightsRequestStatus | undefined> => {
  try {
    const {data} = await axios.get(
      process.env.REACT_APP_FIREBASE_API_URL +
        "/rights/" +
        rightRequestId +
        "/status"
    );
    return data as RightsRequestStatus;
  } catch (err) {
    console.error(err);
    return undefined;
  }
};

export const handleRightsRequest = async (
  rightsRequestId: string,
  consent: string,
  comment?: string
): Promise<RightsRequest | undefined> => {
  try {
    const {data} = await axios.post(
      process.env.REACT_APP_FIREBASE_API_URL +
        "/rights/" +
        rightsRequestId +
        "/consent",
      {
        consent,
        comment,
      }
    );
    return data as RightsRequest;
  } catch (err) {
    console.error(err);
    return undefined;
  }
};

export const handleApproveMultipleRightsRequests = async (
  rightsRequestId: string,
  approvedChildren: string[],
  comment: string
): Promise<RightsRequest | undefined> => {
  try {
    const {data} = await axios.post(
      process.env.REACT_APP_FIREBASE_API_URL +
        "/rights/" +
        rightsRequestId +
        "/consent",
      {
        approvedChildren,
        comment,
      }
    );
    return data as RightsRequest;
  } catch (err) {
    console.error(err);
    return undefined;
  }
};

export const getInstagramAccessToken = async (
  code: string
): Promise<
  | {
      access_token: string;
      userId: string;
    }
  | undefined
> => {
  const getInstagramAccessToken = httpsCallable(
    functions,
    "getInstagramAccessToken"
  );

  try {
    const {data} = await getInstagramAccessToken({
      code: code,
    });
    return data as {
      access_token: string;
      userId: string;
    };
  } catch (err) {
    console.error(err);
    return undefined;
  }
};
